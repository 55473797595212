.cloudbook__baotri {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url('./static/images/h-under-maintenance-background.jpg');
  background-position: bottom right;
  background-size: auto;
  background-repeat: no-repeat;
}

.cloudbook__baotri h1 {
  margin-top: 100px;
  margin-left: 50px;
  font-size: 40px;
  color: #135889;
  letter-spacing: 1px;
  font-family: monospace;
  font-weight: 600;
}