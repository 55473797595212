.spin {
  /* text-align: center;
  background: rgba(0,0,0,0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0; */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}